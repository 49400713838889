/* Chat Timer (30 seconds) */
@keyframes countdown-chat {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 425;
    }
}

@keyframes pulse-red-chat {
    0%, 100% {
        stroke: red;
    }
    50% {
        stroke: #121212;
    }
}

@keyframes pulse-red-dark-chat {
    0%, 100% {
        stroke: red;
    }
    50% {
        stroke: white;
    }
}

.timer-rect-chat {
    transform: rotate(180deg) scale(1, -1);
    transform-origin: center;
    stroke-width: 2;
    fill: none;
    stroke-dasharray: 425;
    animation: countdown-chat 30s linear forwards;
}

.animate-pulse-red-chat {
    animation: countdown-chat 30s linear forwards, pulse-red-chat 1.5s infinite;
}

.dark .animate-pulse-red-chat {
    animation: countdown-chat 30s linear forwards, pulse-red-dark-chat 1.5s infinite;
}

/* User Timer (20 seconds) */
@keyframes countdown-user {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 283;
    }
}

@keyframes pulse-red-user {
    0%, 100% {
        stroke: red;
    }
    50% {
        stroke: #121212;
    }
}

@keyframes pulse-red-dark-user {
    0%, 100% {
        stroke: red;
    }
    50% {
        stroke: white;
    }
}

.timer-rect-user {
    transform: rotate(180deg) scale(1, -1);
    transform-origin: center;
    stroke-width: 2;
    fill: none;
    stroke-dasharray: 283;
    animation: countdown-user 20s linear forwards;
}

.animate-pulse-red-user {
    animation: countdown-user 20s linear forwards, pulse-red-user 1.5s infinite;
}

.dark .animate-pulse-red-user {
    animation: countdown-user 20s linear forwards, pulse-red-dark-user 1.5s infinite;
}
