@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Inter:wght@500&display=swap');

th {
  font-family: 'Dosis', sans-serif;
  text-transform: uppercase;
  letter-spacing: -0.3px;
  font-weight: 500;
  font-size: 0.9rem;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 100;
}
/* Sidebar container remains sticky on large screens */
.sticky-sidebar-container {
  position: sticky;
  top: 0;
  align-self: flex-start;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* First side nav (main one) should be scrollable */
.sticky-sidebar-container > .vaadin-side-nav:first-child {
  flex-grow: 1;
  overflow-y: auto;
}

/* Last side nav (bottom one) sticks to the bottom */
.sticky-sidebar-container > .vaadin-side-nav:last-child {
  position: sticky;
  bottom: 0;
  background-color: inherit;
}

/* Hide the sidebar container on screens smaller than 1024px */
@media (max-width: 1024px) {
  .sticky-sidebar-container {
    display: none; /* Completely hide the sidebars */
  }
}

.options-label {
  font-family: 'Dosis', sans-serif;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  font-weight: 500;
}

svg text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

/* Scrolling text with fade effect */
.scrolling-container {
  width: 160px;
  position: relative;
}

.scrolling-text {
  animation: scroll-left 10s linear infinite;
  padding: 0 15px;
}

@keyframes scroll-left {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.fade {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;
  pointer-events: none;
}

.fade-left {
  left: 0;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.fade-right {
  right: 0;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}

.dark .fade-left {
  background: linear-gradient(to right, #1a1a1a, rgba(18, 18, 18, 0));
}

.dark .fade-right {
  background: linear-gradient(to left, #1a1a1a, rgba(18, 18, 18, 0));
}

tr:hover .fade-left {
  background: linear-gradient(to right, #f3f4f6, rgba(243, 244, 246, 0));
}

tr:hover .fade-right {
  background: linear-gradient(to left, #f3f4f6, rgba(243, 244, 246, 0));
}

.dark tr:hover .fade-left {
  background: linear-gradient(to right, #222222, rgba(34, 34, 34, 0));
}

.dark tr:hover .fade-right {
  background: linear-gradient(to left, #222222, rgba(34, 34, 34, 0));
}

html, body, #root {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.flex-grow {
  flex-grow: 1;
}

footer {
  margin-top: auto;
}

.letters {
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

/* Updated styles for the carousel animation */
.carousel-container {
  position: relative;
  overflow: hidden;
  height: 32px;
}

.carousel-inner {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
}

.carousel-item {
  flex: 0 0 auto;
  margin-bottom: 5px;
}

.carousel-item img {
  height: 32px;
  width: auto;
  object-fit: contain;
}

/* New styles for text carousel (capital, language, language group) */
.text-carousel-container {
  position: relative;
  overflow: hidden;
  height: 20px;

}

.text-carousel-inner {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
}

.text-carousel-item {
  flex: 0 0 auto;
  height: 20px;
  display: flex;
}

.language-group-text,
.capital-text,
.language-text {
  font-weight: 500;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
}





