/* Add this to your MapWorldMap.module.css or equivalent CSS file */

.worldMapContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    overflow: hidden;
}

.worldMapContainer svg {
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(1) translateX(-5%);
    transform-origin: center;
}

#world-map path {
    fill: #ccc;
    stroke: #000;
}

.worldMapContainer path {
    fill-rule: evenodd;
    fill: #f2f2f2;
}

.greyedOut {
    fill: lightgrey !important;
}

.darkTheme path {
    fill: #1a1a1a;
    stroke: #fff;
}

.darkTheme .greyedOut {
    fill: #2A2A2AFF !important;
}
/* Add this to your MapWorldMap.module.css */

.grab-cursor {
    cursor: grab; /* Shows the open hand cursor when hovering */
}

.grabbing-cursor {
    cursor: grabbing; /* Shows the closed hand cursor when holding */
}

.lock-zoom-pan .grab-cursor,
.lock-zoom-pan .grabbing-cursor {
    cursor: default; /* Disable grab behavior if lockZoomPan is enabled */
}
