@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600;400&display=swap');

.unique-letter {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    fill: red !important;
    stroke: none !important;
    color: red !important;
    letter-spacing: -0.5px !important;
}
