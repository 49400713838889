html, body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    color: #333;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.worldMapContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 15px 0;
    overflow: hidden;
}

.worldMapContainer svg {
    display: block;
    width: 100%;
    height: auto;
    transform: scale(0.80) translateY(30%);
    transform-origin: center;
}

.worldMapContainer path {
    fill-rule: evenodd;
    fill: #f2f2f2;
    transition: fill 180ms;
    cursor: pointer;
}

.worldMapContainer path.selected,
.worldMapContainer path:hover {
    fill: #E5BD47;
}

#world-map {
    max-width: 100%;
    height: auto;
    display: block;
}

.greyedOut {
    fill: #d3d3d3 !important;
    pointer-events: none;
}
