/* Quiz.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap');



.btn-hover {
    position: relative;
    overflow: hidden;
    background-size: 300% 100%;
    transition: all 0.4s ease-in-out;
    border-radius: 0.75rem; /* Adjust the border-radius to match the Tailwind rounded-lg */
    height: 100px; /* Adjust button height */
    padding: 12px; /* Adjust padding */
    background-position: 100% 100%;
}

.btn-hover:hover {
    background-position: 0 0;
    transition: all 0.4s ease-in-out;
}

.btn-hover .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit; /* Match the border-radius of the parent */
    z-index: 1; /* Higher z-index to be on top */
    opacity: 0.5; /* Partial transparency */
}

.color-1 {
    background-image: linear-gradient(45deg, #25aae1, #40e495, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.color-2 {
    background-image: linear-gradient(45deg, #f5ce62, #e43603, #fa7199, #e85a19);
    box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}

.color-3 {
    background-image: linear-gradient(45deg, #667eea, #764ba2, #6B8DD6, #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

.color-4 {
    background-image: linear-gradient(45deg, #fc6076, #ff9a44, #ef9d43, #e75516);
    box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75);
}

.color-5 {
    background-image: linear-gradient(45deg, #0ba360, #3cba92, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}

.color-6 {
    background-image: linear-gradient(45deg, #009245, #FCEE21, #00A8C5, #D9E021);
    box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
}

.color-7 {
    background-image: linear-gradient(45deg, #6253e1, #852D91, #A3A1FF, #F24645);
    box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}

.color-8 {
    background-image: linear-gradient(45deg, #29323c, #485563, #2b5876, #4e4376);
    box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}

.color-9 {
    background-image: linear-gradient(45deg, #25aae1, #4481eb, #04befe, #3f86ed);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.color-10 {
    background-image: linear-gradient(45deg, #ed6ea0, #ec8c69, #f7186a, #FBB03B);
    box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}

.color-11 {
    background-image: linear-gradient(45deg, #eb3941, #f15e64, #e14e53, #e2373f);
    box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
}

.label {
    position: absolute;
    bottom: 12px;
    left: 12px;
    z-index: 10;
    font-weight: bold; /* Make labels bold */
    font-family: 'Inter', sans-serif; /* Use font-inter */
}

.icon {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
    font-size: 2.5rem; /* Icon size */
    font-weight: bold; /* Make icon bold */
}

.details {
    font-family: 'Inter', sans-serif;
    font-weight: medium;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 65px; /* Constant height */
}

.details p, .details h2 {
    display: inline-block;
    margin: 0 8px; /* Gap between details */
}

@media (max-width: 768px) {
    .btn-hover {
        height: 80px; /* Smaller button height */
        padding: 10px; /* Smaller padding */
    }

    .icon {
        font-size: 2rem; /* Smaller icon size */
    }

    .grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)); /* Two buttons per row */
    }
}

@media (max-width: 640px) {
    .btn-hover {
        height: 70px; /* Even smaller button height */
        padding: 8px; /* Smaller padding */
    }

    .icon {
        font-size: 1.5rem; /* Smaller icon size */
    }

    .grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr)); /* One button per row */
    }
}



@keyframes countdown {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 283;
    }
}

@keyframes pulse-red {
    0%, 100% {
        stroke: red;
    }
    50% {
        stroke: #121212;
    }
}

@keyframes pulse-red-dark {
    0%, 100% {
        stroke: red;
    }
    50% {
        stroke: white;
    }
}

.timer-rect {
    transform: rotate(180deg) scale(1, -1); /* Rotate and mirror the animation */
    transform-origin: center;
    stroke-width: 2;
    fill: none;
    stroke-dasharray: 283;
    animation: countdown 20s linear forwards; /* Ensure the animation is forwards */
}

.animate-pulse-red {
    animation: countdown 20s linear forwards, pulse-red 1.5s infinite;
}

.dark .animate-pulse-red {
    animation: countdown 20s linear forwards, pulse-red-dark 1.5s infinite;
}

.default-stroke {
    stroke: #121212;
}

.dark .default-stroke {
    stroke: white;
}


.go-back-arrow {
    margin-left: 10px;
    transition: transform 0.2s ease;
    padding-bottom: 8px;
    font-size: 18px;
}
.go-back-arrow {
    margin-right: 4px;
    transition: transform 0.2s ease;
    padding-bottom: 8px;
    font-size: 13px;
    color: #94a3b8;
}
a.text-lg.mt-8:hover .go-back-arrow {
    transform: translateX(-10px);
    color: #5eead4;
}