/* Base styles for the buttons and text */
.btn-shine {
    position: relative; /* Remove absolute positioning */
    padding: 12px 48px;
    color: #fff;
    background: linear-gradient(to right, #9f9f9f 0%, #ffffff 10%, #868686 20%);
    background-size: 300% 100%;  /* Adjust the background size */
    background-position: 0 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    font-weight: 600;
    font-size: 22px;
    text-decoration: none;
    white-space: normal; /* Allow text to wrap if necessary */
    overflow-wrap: break-word; /* Allow breaking long words */
    max-width: 100%; /* Ensure text doesn't overflow */
    text-align: center; /* Center the text */
}

/* Keyframe for shine animation */
@keyframes shine {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -300% 0;
    }
}

/* Flexbox container to center text relative to outer container */
.btn-shine-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Takes up 100% height of the outer parent container */
    width: 100%; /* Full width of the parent container */
    flex-direction: column; /* Stack texts vertically */
    text-align: center; /* Center the text content */
}

/* Outer container to limit the width and keep content centered */
.outer-container {
    max-width: 700px; /* Limit the maximum width of the text container */
    width: 100%; /* Ensure it is responsive */
    margin: 0 auto; /* Center the outer container */
    padding: 0 20px; /* Add some padding to prevent content from touching edges */
}


@-moz-keyframes shine {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -300% 0;
    }
}

@-webkit-keyframes shine {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -300% 0;
    }
}

@-o-keyframes shine {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -300% 0;
    }
}


.percentage-container {
    position: relative;
    width: 45px;
    height: 45px;
}

.percentage-svg {
    width: 100%;
    height: 100%;
}

.percentage-path {
    transition: stroke-dasharray 500ms ease, stroke 500ms ease;
}

.percentage-text {
    font-size: 18px;
    font-weight: bold;
    color: black;
}
