@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

.languages-badge {
    fill-opacity: 0.5 !important;
}

.languages-badge-text {
    fill: black !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
}
