@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

.language-group {
    font-family: 'Inter', sans-serif !important;
    font-weight: 700 !important;
    fill: red !important;
    stroke: none !important;
    color: red !important;
}

.name,
.languages,
.phone-code,
.currency {
    font-family: 'Inter', sans-serif !important;
    font-weight: 700 !important;
    fill: red !important;
    stroke: none !important;
    color: red !important;
}

.languages rect {
    fill: none !important;
}

.languages text {
    fill: black !important;
    stroke: none !important;
}
