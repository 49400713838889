.meta-tag-rect {
    fill-opacity: 1;
    stroke: none;
}

.meta-tag-foreignObject {
    pointer-events: none;
}

.meta-tag-div {
    font-family: Arial, sans-serif;
    font-weight: 400;
    line-height: 1;
    pointer-events: none;
}
