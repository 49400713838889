@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

.game-category-badge {
    fill-opacity: 0.8;
}

.game-category-text {
    font-family: 'Inter', sans-serif !important;
    font-weight: 700 !important;
    stroke: none !important;
}
