@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none; /* Prevents text selection globally */
}

img {
  -webkit-user-drag: none; /* Prevents images from being draggable */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}
.blur-md {
  filter: blur(4px);
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}




/* Custom CSS for Nav Animation */
.nav-link {
  position: relative;
  padding-bottom: 0.5rem;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #4a5568;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #2d3748;
}

.nav-link.active {
  color: #3182ce;
}

.nav-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #3182ce;
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-link.active ~ .nav-underline {
  width: 100%;
}

.nav-link.active + .nav-underline {
  width: 100%;
}
